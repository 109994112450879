<template>
    <div class="item_board" @click="onClickDetail">
      <div class="area_img" :style="{ 'background-image' : `url(${itemData.thumbnail})`}"></div>
      <div class="area_content">
        <span :class="['badge_recommed', `badge_recommed_${itemData.post_type.toLowerCase()}`]">{{ itemData.post_type }}</span>
        <p class="desc_content">{{ itemData.title }}</p>
      </div>
    </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg.vue';
export default {
  name:'RecommedItem',
  props:{
    itemData: Object,
    itemRouteName: String,
  },
  components:{
    IconSvg,
  },
  data(){
    return {
      isShareOpen : false
    }
  },
  methods:{
    onClickDetail(e){
      if( this.itemRouteName ){
        let routeInfo = { name: this.itemRouteName };
        if(this.itemData.link || this.itemData.id){
          routeInfo.params = { id: this.itemData.link || this.itemData.id};
        }
        this.$router.push(routeInfo);
        return;
      }
      this.$emit('onClickBtn',e);
    },
  }
}
</script>
<style scoped>
.item_board{display:block;position:relative;width:400px;cursor:pointer}
.item_board .area_img{padding-top:75%;border-radius:4px 4px 0 0;background-position:center;background-size:auto 100%;background-repeat:no-repeat;background-color:#DDD2BD}

.area_content .badge_recommed{display:inline-block;margin-top:16px;padding:4px 8px;border-radius:2px;font-weight:600;font-size:12px;line-height:16px;vertical-align:top;color:#111}
.area_content .badge_recommed_hot{background-color:#FBAE8A}
.area_content .badge_recommed_new{background-color:#D8CBF4}
.area_content .desc_content{display:block;display:-webkit-box;overflow:hidden;margin-top:16px;font-weight:700;font-size:24px;line-height:36px;word-wrap:break-word;word-break:keep-all;-webkit-line-clamp:2;-webkit-box-orient:vertical;}

/* 모바일 */
@media all and (max-width:510px){
  .item_board{width:100%}
}
</style>