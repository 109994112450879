<template>
  <PageWithLayout>
    <BoardView
      :detailData="viewModel.detailData"
      :isShare="true"
      :isFooterBtnControaler="false"/>
    <div v-if="viewModel.hotDataList.length > 0" class="area_recommed">
      <div class="inner_section">
        <strong class="tit_recommed">추천 포스트</strong>
        <swiper
          class="swiper_recommed"
          :options="swiperRecommedOption">
          <swiper-slide v-for="(item,index) in viewModel.hotDataList" :key="'recommedSwiper'+index">
            <RecommedItem
              :itemData="item"
              itemRouteName="PostDetail"/>
          </swiper-slide>
          <swiper-slide class="swiper-slide-all-btn">
            <router-link :to="$routerPath.POST_LIST" class="btn_all_post area_middle">
             <div class="inner_middle">
                전체보기
                <IconSvg
                  iconName="page_next"
                  iconColor="#232323"
                  :size="12"/>
             </div>
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination swiper-recommed-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="section_banner">
      <div class="inner_section">
        <a href="https://page.stibee.com/subscriptions/162751?groupIds=144911" target="_blank">
          <div class="area_img">
            <div class="inner_img"></div>
          </div>
        </a>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import BoardView from '@/components/common/board/BoardView';
import RecommedItem from '@/components/common/board/RecommedItem';
import IconSvg from '@/components/common/icon/IconSvg.vue';
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'

// viewModel
import PostDetailViewModel from '@/views/blog/viewModel/post/PostDetailViewModel';

export default {
  name:'PostDetail',
  components:{
    PageWithLayout,
    BoardView,
    Swiper,
    SwiperSlide,
    Pagination,
    RecommedItem,
    IconSvg,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  watch:{
    '$route.params.id':{
      deep:true,
      handler(newVal,oldVal){
        if(newVal != oldVal){
          this.viewModel.getPostDetail(newVal)
        }
      }
    }
  },
  data(){
    return{
      viewModel: new PostDetailViewModel(),
      swiperRecommedOption: {
        // autoplay: {
        //   delay: 3000
        // },
        loop: false,
        spaceBetween:20,
        slidesPerView: "auto",
        centeredSlides: false,
        slidesPerView: 1,
        freeMode: false,
        // preventClicks:false,
        // preventClicksPropagation:false,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-recommed-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints:{
          510:{
            spaceBetween:32,
            slidesPerView: "auto",
            centeredSlides: false,
            freeMode: true,
            pagination: false
          }
        }
      },
    }
  },
  metaInfo: {
    meta: [
      {
        property: "description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"og:description",
      },
      {
        property: "twitter:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>
.area_recommed{overflow:hidden;padding-top:80px}
.area_recommed:last-child{padding-bottom:200px}
.area_recommed .tit_recommed{display:block;font-weight:700;font-size:28px;line-height:36px;color:#111}
.area_recommed .swiper_recommed{overflow:visible;margin-top:32px}
.area_recommed .swiper_recommed .swiper-slide{width:400px}
.area_recommed .swiper_recommed .swiper-slide-all-btn{width:111px}
.btn_all_post{display:block;width:111px;height:428px;border-radius:8px;font-weight:700;font-size:18px;line-height:28px;background-color:#F3F1F1;text-align:center}
.btn_all_post .icon_page_next{margin:8px 0 8px 4px}

.section_banner{padding-top:80px}
.section_banner:last-child{padding-bottom:200px}
.section_banner a{display:block}
.section_banner .area_img{width:100%;height:200px}
.section_banner .area_img .inner_img{height:100%;background:url(/assets/images/blog/img_banner.png)no-repeat center 0;background-size:auto 100%;}

/* 모바일 */
@media all and (max-width:1199px){
  .area_recommed:last-child{padding-bottom:48px}

  .section_banner:last-child{padding-bottom:48px}
}
/* 모바일 */
@media all and (max-width:1000px){
  .section_banner .area_img{height:auto}
  .section_banner .area_img .inner_img{height:0;padding-top:43.4%;background:#8f6add url(/assets/images/blog/img_banner_mo.png)no-repeat center 0;background-size:100%}
}
/* 모바일 */
@media all and (max-width:510px){
  .area_recommed .swiper_recommed{overflow:hidden;padding-bottom:42px}
  .area_recommed .swiper_recommed .swiper-slide{width:100%;height:auto}
  .swiper-pagination{height:10px;bottom:0}
  .swiper-pagination >>> .swiper-pagination-bullet{width:10px;height:10px;margin:0 3px;opacity:1;background-color:#D8CBF4;vertical-align:top}
  .swiper-pagination >>> .swiper-pagination-bullet-active{background-color:#8F69DD}
  .btn_all_post{width:100%;height:100%}

}
</style>