import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';

export default class PostViewModel {
  constructor() {
    this.isMobile = false;
    this.detailData = {};
    this.hotDataList = [];
  }
  init(link){
    this.getPostDetail(link);
    this.getHotPostList()
  }
  // 상세
  getPostDetail(link){
    const path = `${apiPath.POST_DETAIL.format(link)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.detailData = resultData;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getHotPostList(){
    const path = `${apiPath.POST_HOT_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      let resultData = success.data;
      this.hotDataList = resultData
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}